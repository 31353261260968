<template>
    <div class="row">
        <div class="large-10 columns" style="padding-top: 50px;">
            <ul class="flex md:flex-row flex-col">
                <li class="flex flex-col nav-item" :class="{active: isCurrentRoute('home')}" @click="goto(route('home'))">
                    <img alt="Home" class="h-32 hidden md:block" src="/public/storage/Icons/icoon-home.png">
                    <a :href='route("home")'>Home</a>
                </li>
                <li class="flex flex-col nav-item" :class="{active: isCurrentRoute('informatie')}" @click="goto(route('informatie'))">
                    <img alt="Informatie" class="h-32 hidden md:block" src="/public/storage/Icons/icoon-info.png">
                    <a :href='route("informatie")'>Info</a>
                </li>
                <li class="flex flex-col nav-item" :class="{active: isCurrentRoute('activiteiten')}" @click="goto(route('activiteiten'))">
                    <img alt="Activiteiten" class="h-32 hidden md:block"
                         src="/public/storage/Icons/icoon-activiteiten.png">
                    <a :href='route("activiteiten")'>Activiteiten</a>
                </li>
                <li class="flex flex-col nav-item" :class="{active: isCurrentRoute('fotos')}" @click="goto(route('fotos'))">
                    <img alt="Foto's" class="h-32 hidden md:block" src="/public/storage/Icons/icoon-fiches.png">
                    <a :href='route("fotos")'>Foto's</a>
                </li>
                <li class="flex flex-col nav-item" :class="{active: isCurrentRoute('actueel')}" @click="goto(route('actueel'))">
                    <img alt="Actueel" class="h-32 hidden md:block" src="/public/storage/Icons/icoon-actueel.png">
                    <a :href='route("actueel")'>Actueel</a>
                </li>
                <li class="flex flex-col nav-item" :class="{active: isCurrentRoute('links')}" @click="goto(route('links'))">
                    <img alt="Links" class="h-32 hidden md:block" src="/public/storage/Icons/icoon-links.png">
                    <a :href='route("links")'>Links</a>
                </li>
                <li :class="{active: isCurrentRoute('contact')}" class="flex flex-col nav-item"
                    @click="goto(route('contact'))">
                    <img alt="Contact" class="h-32 hidden md:block" src="/public/storage/Icons/icoon-contact.png">
                    <a :href='route("contact")'>Contact</a>
                </li>
                <li class="flex flex-col nav-item" :class="{active: isCurrentRoute('inschrijving') || isCurrentRoute('kampen.inschrij') || isCurrentRoute('parkdagen.inschrij') }" @click="goto(route('inschrijvingen'))">
                    <img alt="Inschrijvingen" class="h-32 hidden md:block"
                         src="/public/storage/Icons/icoon-inschrijvingen.svg">
                    <a :href='route("inschrijvingen")'>Inschrijven</a>
                </li>
                <li class="flex flex-col nav-item md:hidden" @click="goto(route('login'))">
                    <a :href="route('login')">Klein Vossen Park</a>
                </li>
            </ul>
        </div>
        <div class="large-2 columns py-0 hidden md:block">
            <h1>
                <a :href="route('login')"><img
                    alt="Klein Vossen Park" src="/public/storage/Icons/logo2.png"></a>
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "Navigation",
    methods: {
        isCurrentRoute(prefix) {
            return route().current().startsWith(prefix);
        },
        goto(url){
            window.location = url;
        }
    },
}
</script>

<style scoped>
.active {
    background-color: #c2e09d;
}

.nav-item {
    height: 80px;
    min-width: 60px;
    padding: 10px 10px 10px 10px;
    margin-right: 10px;
    border-radius: 10px 10px 0 0;
    transition: background-color 300ms ease-out;
}

.nav-item:hover {
    background-color: #933316;
    cursor: pointer;
}


.nav-item:hover > a{
    color: white;
}


.nav-item > img {
    height: 32px;
    margin: 0 auto;
}

.nav-item > a {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #713115;

}

.large-10 .columns {
    padding-bottom: 0;
}

@media screen and (max-width: 767px) {

    ul {
        @apply bg-kvpOrange;
    }

    .nav-item {
        @apply bg-kvpOrange;
        height: 40px;
        margin: 0;
        padding: 10px 10px 10px 10px;
        border-radius: 0;
        border-bottom: #713115 2px solid;
    }

    .active {
        background-color: #c2e09d;
    }



}


</style>
